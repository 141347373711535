import React, { useEffect, useState } from "react";
import io from "socket.io-client";

const socket = io('https://www.nikoserver.site:4000', { transports: ['websocket'] });

const App = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [playerName, setPlayerName] = useState("");
  const [errorMessage, setErrorMessage] = useState(null); // Fehlermeldungen
  const [question, setQuestion] = useState(null);
  const [gameJoined, setGameJoined] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [winners, setWinners] = useState(null);
  const [remainingTime, setRemainingTime] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showIntermediateScreen, setShowIntermediateScreen] = useState(false);
  const [intermediateQuestion, setIntermediateQuestion] = useState(null); // Letzte Frage für Zwischenscreen

  useEffect(() => {
    socket.on("game_started", () => {
      setGameStarted(true);
    });

    socket.on("update_players", (players) => {
      setLeaderboard(Object.values(players));
    });

    socket.on("timer_update", (time) => {
      setRemainingTime(time);
    });

    socket.on("new_question", (questionData) => {
      setQuestion(questionData);
      setSelectedAnswer(null);
      setShowIntermediateScreen(false);
      setIntermediateQuestion(null);
    });

    socket.on("leaderboard_update", (players) => {
      setLeaderboard(Object.values(players));
    });

    socket.on("all_answers_received", ({ question }) => {
      setRemainingTime(0); 
      setIntermediateQuestion(question); 
      setShowIntermediateScreen(true);

      setTimeout(() => {
        setShowIntermediateScreen(false); 
        socket.emit("next_question"); 
      }, 3000);
    });

    socket.on("game_over", ({ winners }) => {
      setWinners(winners);
      setQuestion(null);
    });

    socket.on("game_reset", () => {
      setLeaderboard([]);
      setPlayerName("");
      setQuestion(null);
      setGameJoined(false);
      setGameStarted(false);
      setWinners(null);
      setRemainingTime(0);
      setSelectedAnswer(null);
      setShowIntermediateScreen(false);
      setIntermediateQuestion(null);
    });

    socket.on("error_message", (message) => {
      setErrorMessage(message); 
      setGameJoined(false); 
    });

    return () => {
      socket.off("game_started");
      socket.off("update_players");
      socket.off("timer_update");
      socket.off("new_question");
      socket.off("leaderboard_update");
      socket.off("all_answers_received");
      socket.off("game_over");
      socket.off("game_reset");
      socket.off("error_message");
    };
  }, []);

  const joinGame = () => {
    setErrorMessage(null); // Vorherige Fehlermeldungen löschen (kommen vom Backend)
    socket.emit("join_game", playerName);
    setGameJoined(true); // gültiger Name eingegeben
  };

  const startGame = () => {
    if (!gameStarted) {
      socket.emit("start_game");
      setGameStarted(true);
    }
  };

  const resetGame = () => {
    socket.emit("reset_game");
  };

  const submitAnswer = (selectedAnswer) => {
    if (playerName !== "administnoetig") {
      socket.emit("submit_answer", selectedAnswer);
      setSelectedAnswer(selectedAnswer);
    }
  };

  const renderWinners = () => {
    if (!winners || winners.length === 0) return null;

    if (winners.length === 1) {
      return `Sieger: ${winners[0].name} mit ${winners[0].score} Punkten`;
    } else {
      const names = winners.map((winner) => winner.name).join(" und ");
      return `Sieger: ${names} mit ${winners[0].score} Punkten`;
    }
  };

  return (
    <div className="container mt-5">
      <div className="text-center mb-4">
        <h1>Quiz Game</h1>
      </div>

      {!gameJoined && (
        <div className="d-flex justify-content-center mb-4">
          <input
            type="text"
            className="form-control w-25 me-2"
            placeholder="Name"
            value={playerName}
            onChange={(e) => setPlayerName(e.target.value)}
            disabled={gameStarted}
          />
          <button
            className="btn btn-primary me-2"
            onClick={joinGame}
            disabled={gameStarted}
          >
            Spiel beitreten
          </button>
        </div>
      )}

      {errorMessage && (
        <div className="alert alert-danger text-center">
          {errorMessage}
        </div>
      )}

      {gameJoined && playerName === "administnoetig" && (
        <div className="text-center mb-4">
          {!gameStarted && (
            <button className="btn btn-success me-2" onClick={startGame}>
              Spiel starten
            </button>
          )}
          <button className="btn btn-danger" onClick={resetGame}>
            Spiel zurücksetzen
          </button>
        </div>
      )}

      {showIntermediateScreen && intermediateQuestion && (
        <div className="card p-4 mb-4">
          <h2 className="card-title">Frage: {intermediateQuestion.question}</h2>
          <div className="btn-group">
            {intermediateQuestion.options.map((option, index) => (
              <button
                key={index}
                className={`btn ${
                  option === intermediateQuestion.correctAnswer
                    ? 'btn-success' // Richtige Antwort
                    : option === selectedAnswer
                    ? 'btn-danger' // Falsche Antwort
                    : 'btn-outline-secondary' // Unausgewählte Antwort
                }`}
                disabled
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      )}

      {!showIntermediateScreen && question && playerName !== 'administnoetig' && (
        <div className="card p-4 mb-4">
          <h2 className="card-title">Frage: {question.question}</h2>
          <p>Zeit verbleibend: <strong>{remainingTime} Sekunden</strong></p>
          <div className="btn-group">
            {question.options.map((option, index) => (
              <button
                key={index}
                className={`btn ${selectedAnswer === option ? 'btn-primary' : 'btn-outline-secondary'}`}
                onClick={() => submitAnswer(option)}
                disabled={!!selectedAnswer}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      )}

      {(gameJoined || gameStarted) && (
        <>
          <h2 className="mt-4">Leaderboard</h2>
          <ul className="list-group mb-4">
            {leaderboard
              .filter((player) => player.name !== "administnoetig")
              .sort((a, b) => b.score - a.score)
              .map((player, index) => (
                <li
                  key={index}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  {player.name}
                  <span className="badge bg-primary rounded-pill">{player.score}</span>
                </li>
              ))}
          </ul>
        </>
      )}

      {winners && (
        <div className="alert alert-success text-center">
          <h2>Spiel ist vorbei!</h2>
          <h3>{renderWinners()}</h3>
        </div>
      )}
    </div>
  );
};

export default App;